import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';

const Routes = ({ match, typeUser, storageRegistrantId }) => (
  <Switch>
    {typeUser === 'admin' && (
      <>
        <Route
          path={`${match.url}/dashboard`}
          component={asyncComponent(() => import('./admin/home'))}
        />

        <Route
          path={`${match.url}/admin/list`}
          component={asyncComponent(() => import('./admin/Admins'))}
        />

        <Route
          path={`${match.url}/admin/new`}
          component={asyncComponent(() =>
            import('./admin/Admins/AdminRegister')
          )}
        />

        <Route
          path={`${match.url}/user/list`}
          component={asyncComponent(() => import('./admin/Users'))}
        />

        <Route
          path={`${match.url}/user/register`}
          component={asyncComponent(() => import('./admin/Users/UserRegister'))}
        />

        <Route
          path={`${match.url}/user/registrant/edit`}
          component={asyncComponent(() =>
            import('./admin/Users/RegistrantEdit')
          )}
        />

        <Route
          path={`${match.url}/ccee/import`}
          component={asyncComponent(() => import('./admin/CceeImport'))}
        />

        <Route
          path={`${match.url}/ccee/recs`}
          component={asyncComponent(() => import('./admin/RecImports'))}
        />

        <Route
          path={`${match.url}/ccee/month`}
          component={asyncComponent(() => import('./admin/MonthImports'))}
        />

        <Route
          path={`${match.url}/apiintegration/list`}
          component={asyncComponent(() => import('./admin/ApiIntegration'))}
        />

        <Route
          path={`${match.url}/apiintegration/register`}
          component={asyncComponent(() =>
            import('./admin/ApiIntegration/ApiIntegrationRegister')
          )}
        />

        <Route
          path={`${match.url}/registrants/list`}
          component={asyncComponent(() => import('./admin/Registrants'))}
        />

        <Route
          path={`${match.url}/registrants/new`}
          component={asyncComponent(() =>
            import('./admin/Registrants/NewRegistrant')
          )}
        />

        <Route
          path={`${match.url}/enterprises/list`}
          component={asyncComponent(() => import('./admin/Enterprises'))}
        />

        <Route
          path={`${match.url}/enterprises/new`}
          component={asyncComponent(() =>
            import('./admin/Enterprises/NewEntreprise')
          )}
        />

        <Route
          path={`${match.url}/enterprises/details`}
          component={asyncComponent(() =>
            import('./admin/Enterprises/EnterpriseDetails')
          )}
        />

        <Route
          path={`${match.url}/rec/request`}
          component={asyncComponent(() => import('./admin/EmissionsRequest'))}
        />

        <Route
          path={`${match.url}/rec/approved`}
          component={asyncComponent(() => import('./admin/EmissionsApproved'))}
        />

        <Route
          path={`${match.url}/nf/list`}
          component={asyncComponent(() => import('./admin/NfList'))}
        />
        <Route
          path={`${match.url}/nf/register`}
          component={asyncComponent(() => import('./admin/NfList/NfRegister'))}
        />

        <Route
          path={`${match.url}/report/financial`}
          component={asyncComponent(() => import('./admin/FinancialReport'))}
        />

        <Route
          path={`${match.url}/report/nf`}
          component={asyncComponent(() => import('./admin/NfReport'))}
        />

        <Route
          path={`${match.url}/nfredemption/list`}
          component={asyncComponent(() => import('./admin/NfListRedemption'))}
        />
        <Route
          path={`${match.url}/nfredemption/register`}
          component={asyncComponent(() => import('./admin/NfListRedemption/NfRegister'))}
        />

        <Route
          path={`${match.url}/report/emission`}
          component={asyncComponent(() => import('./admin/EmissionReport'))}
        />

        <Route
          path={`${match.url}/report/enterprise`}
          component={asyncComponent(() => import('./admin/EnterpriseReport'))}
        />

        <Route
          path={`${match.url}/report/registrant`}
          component={asyncComponent(() => import('./admin/RegistrantReport'))}
        />

        <Route
          path={`${match.url}/report/consolidation`}
          component={asyncComponent(() =>
            import('./admin/ConsolidationReport')
          )}
        />

        <Route
          path={`${match.url}/report/redemption`}
          component={asyncComponent(() =>
            import('./admin/RedemptionReport')
          )}
        />

        <Route
          path={`${match.url}/report/right_irec`}
          component={asyncComponent(() => import('./admin/RightIrecReport'))}
        />

        <Route
          path={`${match.url}/report/registrant_contact`}
          component={asyncComponent(() =>
            import('./admin/RegistrantContactReport')
          )}
        />

        <Route
          path={`${match.url}/report/emission_revision`}
          component={asyncComponent(() =>
            import('./admin/EmissionRevisionReport')
          )}
        />

        <Route
          path={`${match.url}/report/user`}
          component={asyncComponent(() => import('./admin/UserReport'))}
        />

        <Route
          path={`${match.url}/report/business`}
          component={asyncComponent(() => import('./admin/BusinessReport'))}
        />

        <Route
          path={`${match.url}/report/normal_emission_adjustment`}
          component={asyncComponent(() =>
            import('./admin/NormalEmissionAdjustmentReport')
          )}
        />

        <Route
          path={`${match.url}/report/self_consumption_emission_adjustment`}
          component={asyncComponent(() =>
            import('./admin/SelfconsumptionEmissionAdjustmentReport')
          )}
        />

        <Route
          path={`${match.url}/report/certificatehistory`}
          component={asyncComponent(() => import('./admin/CertificateHistoryReport'))}
        />

        <Route
          path={`${match.url}/rmd/list`}
          component={asyncComponent(() => import('./admin/RmdList'))}
        />

        <Route
          path={`${match.url}/cegcodes/list`}
          component={asyncComponent(() => import('./admin/CegCodesList'))}
        />

        <Route
          path={`${match.url}/cegcodes/register`}
          component={asyncComponent(() =>
            import('./admin/CegCodesList/CegCodesRegister')
          )}
        />

        <Route
          path={`${match.url}/participant/list`}
          component={asyncComponent(() => import('./admin/Participants'))}
        />

        <Route
          path={`${match.url}/participant/register`}
          component={asyncComponent(() =>
            import('./admin/Participants/ParticipantRegister')
          )}
        />

        <Route
          path={`${match.url}/participants/new`}
          component={asyncComponent(() =>
            import('./admin/Participants/NewParticipant')
          )}
        />

        <Route
          path={`${match.url}/participant/edit`}
          component={asyncComponent(() =>
            import('./admin/Participants/NewParticipant')
          )}
        />

        <Route
          path={`${match.url}/powerSupply/list`}
          component={asyncComponent(() => import('./admin/PowerSupply'))}
        />

        <Route
          path={`${match.url}/powerSupply/register`}
          component={asyncComponent(() =>
            import('./admin/PowerSupply/PowerSupplyRegister')
          )}
        />

        <Route
          path={`${match.url}/generationTechnology/list`}
          component={asyncComponent(() =>
            import('./admin/GenerationTechnology')
          )}
        />

        <Route
          path={`${match.url}/imports_history`}
          component={asyncComponent(() => import('./admin/ImportsHistory'))}
        />

        <Route
          path={`${match.url}/generationTechnology/register`}
          component={asyncComponent(() =>
            import('./admin/GenerationTechnology/GenerationTechnologyRegister')
          )}
        />

        <Route
          path={`${match.url}/register_requests`}
          component={asyncComponent(() => import('./admin/RequestsRegister'))}
        />

        <Route
          path={`${match.url}/enterprises/request`}
          component={asyncComponent(() =>
            import('./admin/RequestsRegister/RequestDetails')
          )}
        />

        <Route
          path={`${match.url}/tutorials/list`}
          component={asyncComponent(() => import('./admin/Tutorials'))}
        />
        <Route
          path={`${match.url}/tutorials/register`}
          component={asyncComponent(() =>
            import('./admin/Tutorials/TutorialsRegister')
          )}
        />

        <Route
          path={`${match.url}/configs/list`}
          component={asyncComponent(() => import('./admin/Configs'))}
        />
        <Route
          path={`${match.url}/configs/register`}
          component={asyncComponent(() =>
            import('./admin/Configs/ConfigsRegister')
          )}
        />

        <Route
          path={`${match.url}/exchange/import`}
          component={asyncComponent(() => import('./admin/Exchange/Import'))}
        />

        <Route
          path={`${match.url}/exchange/list`}
          component={asyncComponent(() => import('./admin/Exchange'))}
        />
      </>
    )}
    {(typeUser === 'client' ||
      typeUser === 'client_admin' ||
      typeUser === 'client_financial') && (
      <>
        <Route
          path={`${match.url}/dashboard`}
          component={asyncComponent(() => import('./customer/home'))}
        />

        <Route
          path={`${match.url}/enterprises/list`}
          component={asyncComponent(() => import('./customer/Enterprises'))}
        />

        <Route
          path={`${match.url}/enterprises/new`}
          component={asyncComponent(() =>
            import('./customer/Enterprises/NewEntreprise')
          )}
        />

        <Route
          path={`${match.url}/enterprises/details`}
          component={asyncComponent(() =>
            import('./customer/Enterprises/EnterpriseDetails')
          )}
        />

        <Route
          path={`${match.url}/nf/list`}
          component={asyncComponent(() => import('./customer/NfList'))}
        />

        <Route
          path={`${match.url}/registrant`}
          component={asyncComponent(() =>
            import('./customer/RegistrantRegister')
          )}
        />

        <Route
          path={`${match.url}/users/list`}
          component={asyncComponent(() => import('./customer/Users'))}
        />

        <Route
          path={`${match.url}/users/register`}
          component={asyncComponent(() =>
            import('./customer/Users/UserRegister')
          )}
        />

        <Route
          path={`${match.url}/rec`}
          component={asyncComponent(() => import('./customer/RecEmission'))}
        />

        <Route
          path={`${match.url}/register_enterprise`}
          component={asyncComponent(() =>
            import('./customer/EnterprisesRegister')
          )}
        />

        <Route
          path={`${match.url}/connection_with_participant/register`}
          component={asyncComponent(() =>
            import('./customer/Registrants/EditRegistrant')
          )}
        />

        <Route
          path={`${match.url}/connection_with_participant/list`}
          component={asyncComponent(() =>
            import('./customer/ConnectionWithParticipant')
          )}
        />

        <Route
          path={`${match.url}/connection_with_participant/enterprises`}
          component={asyncComponent(() =>
            import('./customer/ConnectionWithParticipant/Enterprises')
          )}
        />

        <Route
          path={`${match.url}/report/emission`}
          component={asyncComponent(() => import('./customer/EmissionReport'))}
        />

        <Route
          path={`${match.url}/report/certificatehistory`}
          component={asyncComponent(() => import('./customer/CertificateHistoryReport'))}
        />

        <Route
          path={`${match.url}/report/nf`}
          component={asyncComponent(() => import('./customer/NfReport'))}
        />

        <Route
          path={`${match.url}/tutorials/list`}
          component={asyncComponent(() => import('./customer/Tutorials'))}
        />
      </>
    )}

    {typeUser === 'client_admin_participant' && (
      <>
        <Route
          path={`${match.url}/dashboard`}
          component={asyncComponent(() => import('./participant/Retirement'))}
        />

        <Route
          path={`${match.url}/retirement/list`}
          component={asyncComponent(() => import('./participant/Retirement'))}
        />

        <Route
          path={`${match.url}/retirement/new`}
          component={asyncComponent(() =>
            import('./participant/Retirement/newRetirement')
          )}
        />

        <Route
          path={`${match.url}/redemption/list`}
          component={asyncComponent(() => import('./participant/Redemption'))}
        />

        <Route
          path={`${match.url}/redemption/new`}
          component={asyncComponent(() =>
            import('./participant/Redemption/newRedemption')
          )}
        />

        <Route
          path={`${match.url}/beneficiary/list`}
          component={asyncComponent(() => import('./participant/Beneficiary'))}
        />

        <Route
          path={`${match.url}/beneficiary/new`}
          component={asyncComponent(() =>
            import('./participant/Beneficiary/newBeneficiary')
          )}
        />

        <Route
          path={`${match.url}/transfer/list`}
          component={asyncComponent(() => import('./participant/Transfer'))}
        />

        <Route
          path={`${match.url}/transfer/new`}
          component={asyncComponent(() =>
            import('./participant/Transfer/newTransfer')
          )}
        />

        <Route
          path={`${match.url}/certificates/list`}
          component={asyncComponent(() => import('./participant/Certificates'))}
        />

        <Route
          path={`${match.url}/financial/list`}
          component={asyncComponent(() => import('./participant/Financial'))}
        />

        <Route
          path={`${match.url}/users/list`}
          component={asyncComponent(() => import('./participant/Users'))}
        />

        <Route
          path={`${match.url}/users/register`}
          component={asyncComponent(() =>
            import('./customer/Users/UserRegister')
          )}
        />

        <Route
          path={`${match.url}/nf/participant/list`}
          component={asyncComponent(() =>
            import('./participant/NfList')
          )}
        />

        <Route
          path={`${match.url}/beneficiary_consumption/import`}
          component={asyncComponent(() => import('./participant/BeneficiaryConsumption/Import'))}
        />

        <Route
          path={`${match.url}/beneficiary_consumption/list`}
          component={asyncComponent(() => import('./participant/BeneficiaryConsumption'))}
        />

        <Route
          path={`${match.url}/connection_with_registrant/connection`}
          component={asyncComponent(() => import('./participant/ConnectionWithRegistrant/Connection'))}
        />
        <Route
          path={`${match.url}/connection_with_registrant/list`}
          component={asyncComponent(() => import('./participant/ConnectionWithRegistrant'))}
        />
        <Route
          path={`${match.url}/connection_with_registrant/enterprises`}
          component={asyncComponent(() => import('./participant/ConnectionWithRegistrant/Enterprises'))}
        />
        <Route
          path={`${match.url}/connection_with_registrant/enterprise_details`}
          component={asyncComponent(() => import('./participant/ConnectionWithRegistrant/EnterpriseDetails'))}
        />

        <Route
          path={`${match.url}/automation/list`}
          component={asyncComponent(() => import('./participant/Automation'))}
        />

        <Route
          path={`${match.url}/automation/new`}
          component={asyncComponent(() =>
            import('./participant/Automation/newAutomation')
          )}
        />

        <Route
          path={`${match.url}/automationlog/list`}
          component={asyncComponent(() => import('./participant/AutomationLog'))}
        />
      </>
    )}

    {/* <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/> */}
  </Switch>
);

export default withRouter(Routes);
