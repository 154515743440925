export const Types = {
  GET_REDEMPTIONS_AUTOMATIONS_LOGS: 'GET_REDEMPTIONS_AUTOMATIONS_LOGS',
  GET_REDEMPTIONS_AUTOMATIONS_LOGS_SUCCESS: 'GET_REDEMPTIONS_AUTOMATIONS_LOGS_SUCCESS',
  GET_REDEMPTIONS_AUTOMATIONS_LOGS_ERROR: 'GET_REDEMPTIONS_AUTOMATIONS_LOGS_ERROR',
};

const INIT_STATE = {
  data: null,
  redemptionAutomation: null,
  loading: false,
  error: null,
  postSuccess: false,
  deleteSuccess: false,

  amount: 10,
};

const REDEMPTION_ERROR = {
  CEG_NOT_FOUND:
    'CEG não cadastrado na plataforma. Contate o administrador do sistema',
  CEG_HAS_ALREADY_BEEN_USED: 'CEG já cadastrado em outro empreendimento.',
};

export default function RedemptionAutomationLog(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_REDEMPTIONS_AUTOMATIONS_LOGS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_REDEMPTIONS_AUTOMATIONS_LOGS_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_REDEMPTIONS_AUTOMATIONS_LOGS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

export const Creators = {
  getRedemptionsAutomationsLogs: payload => ({
    type: Types.GET_REDEMPTIONS_AUTOMATIONS_LOGS,
    payload,
  }),
  getRedemptionsAutomationsLogsSuccess: payload => ({
    type: Types.GET_REDEMPTIONS_AUTOMATIONS_LOGS_SUCCESS,
    payload,
  }),
  getRedemptionsAutomationsLogsError: payload => ({
    type: Types.GET_REDEMPTIONS_AUTOMATIONS_LOGS_ERROR,
    payload,
  }),
};

