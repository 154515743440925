import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as RedemptionAutomationLogActions,
  Types as RedemptionAutomationLogTypes,
} from 'store/ducks/RedemptionAutomationLog';
import api from '../../util/Api';

function* getRedemptionsAutomationsLogs({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/redemptionsautomationslogs', {
      params: payload,
    });
    if (status === 200) {
      yield put(RedemptionAutomationLogActions.getRedemptionsAutomationsLogsSuccess(data));
    }
  } catch (error) {
    yield put(
      RedemptionAutomationLogActions.getRedemptionsAutomationsLogsError('Erro ao listar logs de automacao')
    );
  }
}

function* getRedemptionsAutomationsLogsWatcher() {
  yield takeLatest(RedemptionAutomationLogTypes.GET_REDEMPTIONS_AUTOMATIONS_LOGS, getRedemptionsAutomationsLogs);
}

export default function* rootSaga() {
  yield all([
    fork(getRedemptionsAutomationsLogsWatcher)
  ]);
}
